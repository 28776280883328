import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaRegClock, FaCheckCircle, FaPhoneAlt } from "react-icons/fa";
import { FaCircleXmark, FaLocationArrow, FaRegMoneyBill1 } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Alertdialog from '../../components/alertdialog/Alertdialog';
import { getOrdersUserById, updateOrder } from './Util';


const isAdminLoggedIn = () => {
    return localStorage.getItem('admin');
}

type Order = {
    order_id: string;
    fullname: string;
    phone: string;
    alt_phone: string;
    payment_id:string;
    delivery_address: string;
    create_time: string;
    status: string;
    items: string;
    isCancellable: number;
}
type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', message: string }
type FailedState = { state: 'error', errorMessage: string }
type OrdersState = LoadingState | SuccessState | FailedState

function UserOrders() {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('userId');
    const userName = queryParams.get('username');
    const admin = useRef<any>(isAdminLoggedIn());
    const [state, setState] = useState<OrdersState | null>(null);
    const [orders, setOrders] = useState<Order[]>([]);
    const isMountedRef = useRef<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMountedRef.current) {
            fetchOrders();
            isMountedRef.current = true;
        }
    }, []);

    const fetchOrders = async () => {
        if (admin.current && userId) {
            setState({ state: 'loading' });
            try {
                const orderRes = await getOrdersUserById(admin.current, userId);
                if (orderRes) {
                    setOrders(orderRes);
                }
                setState({ state: 'success', message: 'Accessed' });
            } catch (error: any) {
                let errorMessage = 'Internal Server Error';
                if (error.code === 'ERR_NETWORK') {
                    errorMessage = error.message;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage = error.response?.data?.error || 'Bad Request Error';
                }
                console.log(errorMessage);
                setState({ state: 'error', errorMessage })
            }
        }
    };

    const onAlertClose = (state: string) => {
        // if (state === 'error') {
        //     localStorage.removeItem('admin');
        //     window.location.replace('/');
        // } else {
        //     setState(null);
        // }
        setState(null);
    }

    const onUpdated = (order_id: string, status: string) => {
        setOrders(prevMenus =>
            prevMenus.map(order =>
                order.order_id === order_id ? { ...order, status } : order
            )
        );
    }

    return state?.state !== 'loading' ? (
        <section className="relative h-full w-full max-w-[1280px] box-border p-3">
            {
                orders.length === 0 && <InitialMessage message='No orders found!' />
            }
            <div className="absolute top-0 left-0 right-0 h-14 flex items-center gap-6 border-b bg-white text-slate-900 box-border px-2">
                <button className="text-lg px-3" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <h1 className="text-xl font-semibold">Orders of {userName}</h1>
            </div>

            <div className="absolute top-14 left-0 right-0 bottom-0 flex flex-col gap-10 p-6 box-border overflow-auto">
                <ul className="flex flex-col gap-3 h-full box-border py-2">
                    {
                        orders.map(order => (
                            <li className={`flex items-center flex-wrap justify-between box-border p-4 rounded gap-1 bg-gray-200`} key={order.order_id}>
                                <div className="flex flex-wrap gap-2 mr-6 ">
                                    <p className="text-sm text-center  font-medium bg-gray-300 px-3 py-1 rounded-full">Id: {order.order_id}</p>
                                    <Status admin={admin.current} order_id={order.order_id} val={order.status} onUpdateFailed={(e) => setState({ state: 'error', errorMessage: e })} onUpdated={onUpdated} />
                                </div>
                                <p className="min-w-[250px] text-sm flex-1  font-medium text-slate-700 flex items-center gap-3 truncate"><FaRegUser />{order.fullname}</p>
                                <p className="min-w-[250px] text-sm flex-1 text-slate-700 flex items-center gap-3 truncate"><FaRegClock />{order.create_time}</p>
                                <button className="text-xs font-medium text-white bg-blue-500 px-3 py-1 rounded-full" onClick={() => navigate(`/orders/${order.order_id}`)}>More</button>
                                {
                                    order.payment_id &&
                                    <button className="text-xs font-medium text-blue-800 bg-gray-300 px-3 py-1 rounded-full" onClick={() => navigate(`/payments/${order.payment_id}`)}>Payment details</button>
                                }
                            </li>
                        ))
                    }
                </ul>
            </div>
            {
                state && state.state == 'error' &&
                <Alertdialog state='error' button_name='Close' message={state.errorMessage} onButtonClick={onAlertClose} />
            }
        </section>
    ) : <InitialMessage message='Loading...' />
}


const InitialMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <label className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">{message}</label>
    )
}

const Status: React.FC<{ admin: string, order_id: string, val: string, onUpdateFailed: (message: string) => void, onUpdated: (order_id: string, statue: string) => void }> = ({ admin, order_id, val, onUpdateFailed, onUpdated }) => {
    const handleUpdate = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        try {
            const updateRes = await updateOrder(admin, order_id, { status: newValue });
            if (updateRes) onUpdated(order_id, newValue);
        } catch (error: any) {
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
            onUpdateFailed(errorMessage);
        }
    }

    return (
        <select className={`text-sm text-center font-medium bg-gray-300 px-3 py-1 rounded-full ${setStatusStyle(val)}`} id="status" value={val} onChange={handleUpdate}>
            <option value="pending">Pending</option>
            <option value="confirmed">Confirmed</option>
            <option value="delivered">Delivered</option>
            <option value="cancelled">Cancelled</option>
        </select>
    )
}

function setStatusStyle(status: string) {
    switch (status) {
        case 'delivered':
            return 'text-green-700';
        case 'cancelled':
            return 'text-red-700';
        case 'confirmed':
        case 'pending':
            return 'text-blue-700';
        default:
            return 'text-slate-700';
    }
}

const getStatusIcon = (status: string) => {
    switch (status) {
        case 'delivered':
        case 'confirmed':
        case 'pending':
            return <FaCheckCircle />
        case 'cancelled':
            return <FaCircleXmark />
        default:
            return <label>Status</label>

    }
}

export default UserOrders