import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import './App.css';
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Orders from "./pages/orders/Orders";
import Items from "./pages/items/Items";
import Reports from "./pages/reports/Reports";
import { useEffect, useState } from "react";
import Footer from "./components/footer/Footer";
import Payments from "./pages/payments/Payments";
import Users from "./pages/users/Users";
import OrderDetail from "./pages/orderdetail/OrderDetail";
import Refunds from "./pages/refunds/Refunds";
import UserOrders from "./pages/orders/UserOrders";
import PaymentDetails from "./pages/paymentdetails/PaymentDetails";
const queryClient = new QueryClient();

const isAdminLoggedIn = () => {
  return localStorage.getItem('admin');
}

function App() {
  const Layout = () => {
    return (
      <div >
        {/* <Navbar /> */}
        {/* <Sidebar /> */}
        <main className="fixed top-0 left-0 right-0 bottom-[70px] bg-white flex justify-center overflow-auto">
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </main>
        <Footer/>
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAdminLoggedIn() !== null ? <Layout /> : <Navigate to='login' replace={true} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:order_id" element={<OrderDetail />} />
          <Route path="/orders/user" element={<UserOrders />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/payments/:payment_id" element={<PaymentDetails />} />
          <Route path="/refunds" element={<Refunds />} />
          <Route path="/items" element={<Items />} />
          <Route path="/users" element={<Users />} />
          <Route path="/reports" element={<Reports />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
