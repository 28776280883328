// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function getPaymentById(token: string, payment_id: number) {
  try {
    const response = await axios.get(`${apiUrl}api//payments/${payment_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

export { getPaymentById };
