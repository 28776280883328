import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading';
import Alertdialog from '../../components/alertdialog/Alertdialog';
import { login } from './Util';

type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', accessToken: string }
type FailedState = { state: 'error', errorMessage: string }
type LoginState = LoadingState | SuccessState | FailedState
function Login() {
    const [formData, setFormData] = useState<{ username: string, password: string }>({ username: "", password: "" });
    const [state, setState] = useState<LoginState | null>(null);

    useEffect(() => {
        if (state?.state === 'success') {
            localStorage.setItem('admin', state.accessToken);
            window.location.replace('/');
        }
    }, [state]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (formData.username !== '' && formData.password !== '') {
            setState({ state: 'loading' });
            try {
                const loginRes = await login(formData);
                if (loginRes.state === 'success') {
                    setState({ state: 'success', accessToken: loginRes.accessToken });
                }
            } catch (error: any) {
                let errorMessage = 'Internal Server Error';
                if (error.code === 'ERR_NETWORK') {
                    errorMessage = error.message;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage = error.response?.data?.error || 'Bad Request Error';
                }
                console.log(errorMessage);
                setState({ state: 'error', errorMessage });
            }
        }

    }
    return (
        <section className="fixed top-0 left-0 right-0 bottom-0 flex justify-center">
            <div className="max-w-[400px] w-[90%] flex flex-col">
                <div className='flex-1'></div>
                <div className="">
                    <h1 className="text-2xl font-medium">Admin Login</h1>
                    <form className="mt-3 flex flex-col gap-3" onSubmit={handleSubmit}>
                        <input className="h-10 box-border px-4 border-2 border-black rounded font-medium" type="text" placeholder='enter username' name='username' required onChange={handleInputChange} />
                        <input className="h-10 box-border px-4 border-2 border-black rounded font-medium" type="password" placeholder='enter password' name='password' required onChange={handleInputChange} />
                        <button className="h-10 bg-black text-white rounded font-medium flex items-center justify-center" type='submit'>{state?.state === 'loading' ? <ReactLoading type={'balls'} color={'white'} height={30} width={30} /> : 'Login'}</button>
                    </form>
                </div>
                <div className='flex-1'></div>
            </div>
            {
                state && state.state === 'error' &&
                <Alertdialog state={'error'} button_name={'Close'} message={state.errorMessage} onButtonClick={() => setState(null)} />
            }
        </section>
    )
}

export default Login