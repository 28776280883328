import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { FaPhoneAlt, FaRegClock, FaRegUser, FaLocationArrow } from 'react-icons/fa';
import { FaRegMoneyBill1 } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { getPaymentById } from './Util';


const isAdminLoggedIn = () => {
    return localStorage.getItem('admin');
}

type Payment = {
    s_no: number;
    order_id?: number;
    fullname?: string;
    phone?: string;
    amount?: number;
    payment_mode?: string;
    transaction_id: string;
    status?: string;
    payment_date?: string;
}



type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', message: string }
type FailedState = { state: 'error', errorMessage: string }
type PaymentState = LoadingState | SuccessState | FailedState

function PaymentDetails() {
    const { payment_id } = useParams();
    const admin = useRef<any>(isAdminLoggedIn());
    const [payment, setPayment] = useState<Payment | null>(null);
    const [state, setState] = useState<PaymentState | null>(null);
    const navigate = useNavigate();
    const isMountedRef = useRef<boolean>(false);


    useEffect(() => {
        if (!isMountedRef.current) {
            fetchPayment();
            isMountedRef.current = true;
        }
    }, []);


    const fetchPayment = async () => {
        if (admin.current && payment_id) {
            setState({ state: 'loading' });
            try {
                const paymentRes = await getPaymentById(admin.current, parseInt(payment_id));
                if (paymentRes) {
                    setPayment(paymentRes);
                    setState({ state: 'success', message: 'Accessed' });
                }
                console.log(paymentRes);
            } catch (error: any) {
                let errorMessage = 'Internal Server Error';
                if (error.code === 'ERR_NETWORK') {
                    errorMessage = error.message;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage = error.response?.data?.error || 'Bad Request Error';
                }
                console.log(errorMessage);
                setState({ state: 'error', errorMessage })
            }
        }
    };
    return (
        <section className="relative h-full w-full max-w-[1280px] box-border p-3">
            <div className="absolute top-0 left-0 right-0 h-14 flex items-center gap-6 border-b bg-white text-slate-900 box-border px-2">
                <button className="text-lg px-3" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <h1 className="text-lg font-semibold">Payment Id : {payment_id}</h1>
            </div>

            {
                state?.state == 'loading' &&
                <InitialMessage message='Loading....' />
            }

            {
                state?.state == 'error' &&
                <InitialMessage message={state.errorMessage} />
            }

            {payment &&
                <div className="absolute top-14 left-0 right-0 bottom-0 flex flex-col gap-10 p-4 box-border overflow-auto">
                    <div className="flex flex-col gap-6 justify-between bg-gray-100 box-border p-6 rounded">
                        <p className="text-4xl font-bold">₹{payment.amount}</p>
                        <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4">
                            <p className="flex items-center justify-center gap-3 font-medium p-3 bg-gray-50 rounded"><FaRegClock />{payment.payment_date}</p>
                            <p className="flex items-center justify-center gap-3 font-medium p-3 bg-gray-50 rounded"><FaRegUser />{payment.fullname}</p>
                            <p className="flex items-center justify-center gap-3 font-medium p-3 bg-gray-50 rounded"><FaPhoneAlt />{payment.phone}</p>
                            <p className="flex items-center justify-center gap-3 font-medium p-3 bg-gray-50 rounded"><FaRegMoneyBill1 />{capitalizeFirstLetter(payment.payment_mode||'')}</p>
                            <p className="flex items-center justify-center gap-3 font-medium p-3 bg-gray-50 rounded">Transaction ID: {payment.transaction_id||'NULL'}</p>
                            <p className="flex items-center justify-center gap-3 font-medium p-3 bg-gray-50 rounded">Order ID: {payment.order_id}</p>
                            <p className="flex items-center justify-center gap-3 font-medium p-3 bg-gray-50 rounded">Status: <span className={`${setPaymentStatusStyle(payment.status || '')}`}>{payment.status}</span></p>
                        </div>
                    </div>
                </div>
            }
        </section>
    )
}

const InitialMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <label className="fixed top-14 left-0 right-0 bottom-0 flex items-center justify-center">{message}</label>
    )
}
function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function setPaymentStatusStyle(status: string) {
    switch (status) {
        case 'PAYMENT_SUCCESS':
            return 'text-green-700';
        case 'PAYMENT_PENDING':
            return 'text-yellow-700';
        default:
            return 'text-red-700';
    }
}

export default PaymentDetails