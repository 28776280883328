import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { FaRegUser } from "react-icons/fa";
import { FaRegClock, FaCheckCircle, FaPhoneAlt } from "react-icons/fa";
import { FaCircleXmark, FaLocationArrow, FaRegMoneyBill1 } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import { getOrderDetails, getOrderItems, updateOrder } from './Util';

const isAdminLoggedIn = () => {
    return localStorage.getItem('admin');
}

type Order = {
    fullname?: string;
    user_id?: string;
    order_id?: number;
    phone?: string;
    delivery_address?: string;
    amount?: number;
    payment_id?: number;
    payment_mode?: string;
    payment_status?: string;
    payment_date?: string;
    create_time?: string;
    status?: string;
    refund_status?: string;
    items?: string;
    isCancellable?: number;
    isRefundable?: number;
}

type OrderItem = { img: string, name: string, price: number, quantity: number }

type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', message: string }
type FailedState = { state: 'error', errorMessage: string }
type OrdersState = LoadingState | SuccessState | FailedState

function OrderDetail() {
    const { order_id } = useParams();
    const admin = useRef<any>(isAdminLoggedIn());
    const [state, setState] = useState<OrdersState | null>(null);
    const [order, setOrder] = useState<Order | null>(null);
    const isMountedRef = useRef<boolean>(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (!isMountedRef.current) {
            fetchOrderById();
            isMountedRef.current = true;
        }
    }, []);


    const fetchOrderById = async () => {
        if (admin.current && order_id) {
            setState({ state: 'loading' });
            try {
                const orderRes = await getOrderDetails(admin.current, order_id);
                if (orderRes) {
                    setOrder(orderRes);
                    setState({ state: 'success', message: 'Acccessed' })
                };
            } catch (error: any) {
                let errorMessage = 'Internal Server Error';
                if (error.code === 'ERR_NETWORK') {
                    errorMessage = error.message;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage = error.response?.data?.error || 'Bad Request Error';
                }
                console.log(errorMessage);
                setState({ state: 'error', errorMessage });
            }
        }
    };

    const onUpdated = (status: string) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            status: status
        }));
    }


    return (
        <section className="relative h-full w-full max-w-[1280px] box-border p-3">
            <div className="absolute top-0 left-0 right-0 h-14 flex items-center gap-6 border-b bg-white text-slate-900 box-border px-2">
                <button className="text-lg px-3" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <h1 className="text-lg font-semibold">Order Id : {order_id}</h1>
            </div>

            {
                state?.state == 'loading' &&
                <InitialMessage message='Loading....' />
            }

            {
                state?.state == 'error' &&
                <InitialMessage message={state.errorMessage} />
            }

            {order &&
                <div className="absolute top-14 left-0 right-0 bottom-0 flex flex-col gap-10 p-6 box-border overflow-auto">
                    <div className="flex flex-col gap-3 justify-center">
                        <p className="flex items-center gap-3 font-medium"><FaRegClock />{order?.create_time}</p>
                        <p className="flex items-center gap-3 font-medium"><FaRegUser />{order?.fullname}</p>
                        <p className="flex items-center gap-3 font-medium"><FaPhoneAlt />{order?.phone}</p>
                        <p className="flex items-center gap-3 font-medium"><FaLocationArrow />{order?.delivery_address}</p>
                        <div className="flex items-center gap-4">
                            <p className="flex items-center gap-3 font-medium"><FaRegMoneyBill1 />{capitalizeFirstLetter(order?.payment_mode || '')}</p>
                            <p className={`text-sm font-medium bg-gray-200 px-3 rounded-full ${setPaymentStatusStyle(order?.payment_status ? order.payment_status : '')}`}>{order?.payment_status ? order.payment_status : 'NOT AVAILABLE'}</p>
                            <p className={`text-sm font-medium bg-gray-200 px-3 rounded-full ${setPaymentStatusStyle(order?.refund_status ? order.refund_status : '')}`}>{order?.refund_status}</p>
                        </div>
                        {
                            order.order_id && order.status &&
                            <Status admin={admin.current} order_id={order.order_id} val={order.status} onUpdateFailed={(e) => setState({ state: 'error', errorMessage: e })} onUpdated={onUpdated} />
                        }
                    </div>
                    {
                        admin.current && order.order_id &&
                        <OrderItems admin={admin.current} order_id={order.order_id} />
                    }
                </div>
            }
        </section>
    )
}

const OrderItems: React.FC<{ admin: string, order_id: number }> = ({ admin, order_id }) => {
    const [orderItems, setOrderItems] = useState<OrderItem[]>([]);

    useEffect(() => {
        fetchOrderItems();
    }, [])


    const fetchOrderItems = async () => {
        try {
            const itemRes = await getOrderItems(admin, order_id);
            if (itemRes) setOrderItems(itemRes);
            console.log(itemRes);
        } catch (error: any) {
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
            // setState({ state: 'error', errorMessage });
        }
    };

    return (
        <div className="flex flex-col gap-3">
            <h2 className="text-md font-medium">Items</h2>

            <table className="w-full text-sm font-medium border text-black">
                <thead className="bg-black">
                    <tr className="text-white">
                        <th className="border box-border p-2 text-start">Name</th>
                        <th className="border box-border p-2 text-start">Price</th>
                        <th className="border box-border p-2 text-start w-2">Qnty</th>
                        <th className="border box-border p-2 text-start">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItems.map((item, index) => (
                        <tr key={index}>
                            <td className="border box-border p-2 text-start">{item.name}</td>
                            <td className="border box-border p-2 text-start">₹{item.price}</td>
                            <td className="border box-border p-2 text-start w-2">{item.quantity}</td>
                            <td className="border box-border p-2 text-start">₹{item.price * item.quantity}</td>
                        </tr>
                    ))}
                </tbody>
                <thead>
                    <tr>
                        <th className="border box-border p-2 text-start"></th>
                        <th className="border box-border p-2 text-start" colSpan={2}>Total Amount</th>
                        <th className="border box-border p-2 text-start">₹{orderItems.reduce((i: number, o: any) => i + (o.price * o.quantity), 0)}</th>
                    </tr>
                </thead>

            </table>

        </div>
    );
};

const Status: React.FC<{ admin: string, order_id: number, val: string, onUpdateFailed: (message: string) => void, onUpdated: (status: string) => void }> = ({ admin, order_id, val, onUpdateFailed, onUpdated }) => {
    const handleUpdate = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        try {
            const updateRes = await updateOrder(admin, order_id, { status: newValue });
            if (updateRes) onUpdated(newValue)
        } catch (error: any) {
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
            onUpdateFailed(errorMessage);
        }
    }

    return (
        <select className={`max-w-[150px] px-3 py-1 font-medium rounded-full ${setStatusStyle(val)}`} name="status" id="status" value={val} onChange={handleUpdate}>
            <option value="pending">Pending</option>
            <option value="confirmed">Confirmed</option>
            <option value="cancelled">Cancelled</option>
            <option value="delivered">Delivered</option>
        </select>
    )
}


const InitialMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <label className="fixed top-14 left-0 right-0 bottom-0 flex items-center justify-center">{message}</label>
    )
}

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function setStatusStyle(status: string) {
    switch (status) {
        case 'delivered':
            return 'text-green-700';
        case 'cancelled':
            return 'text-red-700';
        case 'confirmed':
        case 'pending':
            return 'text-blue-700';
        default:
            return 'text-slate-700';
    }
}

function setPaymentStatusStyle(status: string) {
    switch (status) {
        case 'PAYMENT_SUCCESS':
            return 'text-green-700';
        case 'PAYMENT_PENDING':
            return 'text-yellow-700';
        default:
            return 'text-red-700';
    }
}

export default OrderDetail