import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronDown, faClock } from '@fortawesome/free-solid-svg-icons';
import { FaRegClock, FaCheckCircle, FaPhoneAlt } from "react-icons/fa";
import { FaCircleXmark, FaLocationArrow, FaRegMoneyBill1 } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Alertdialog from '../../components/alertdialog/Alertdialog';
import { getRefunds, initiateRefund, updateRefunds } from './Util';
import ReactLoading from 'react-loading';


const isAdminLoggedIn = () => {
  return localStorage.getItem('admin');
}

type Refund = {
  s_no: number;
  payment_id: number;
  order_id: number;
  original_transaction_id: string;
  client: string;
  amount: string;
  create_date: string;
  status: string;
}
type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', message: string }
type FailedState = { state: 'error', errorMessage: string }
type RefundState = LoadingState | SuccessState | FailedState

function Refunds() {
  const admin = useRef<any>(isAdminLoggedIn());
  const [state, setState] = useState<RefundState | null>(null);
  const [refunds, setRefunds] = useState<Refund[]>([]);
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10000);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [initRefund, setInitRefund] = useState<{ s_no: number, original_transaction_id: string } | null>(null);
  const isMountedRef = useRef<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMountedRef.current) {
      fetchRefunds();
      isMountedRef.current = true;
    }
  }, []);


  const fetchRefunds = async () => {
    if (admin.current)
      setState({ state: 'loading' });
    try {
      const refRes = await getRefunds(admin.current, page, limit);
      if (refRes && refRes.refunds && refRes.totalPages) {
        setRefunds(refRes.refunds);
        setTotalPages(refRes.totalPages);
      }
      setState(null);
    } catch (error: any) {
      let errorMessage = 'Internal Server Error';
      if (error.code === 'ERR_NETWORK') {
        errorMessage = error.message;
      } else if (error.code === 'ERR_BAD_REQUEST') {
        errorMessage = error.response?.data?.error || 'Bad Request Error';
      }
      console.log(errorMessage);
      setState({ state: 'error', errorMessage })
    }

  };


  const onAlertClose = (state: 'error' | 'success') => {
    if (state === 'error') {
      setState(null);
      setInitRefund(null);
    } else {
      window.location.reload();
    }
  };

  const onRefundInitiate = async (original_transaction_id: string) => {
    if (admin.current && original_transaction_id) {
      try {
        const initiateRes = await initiateRefund(admin.current, { original_transaction_id });
        setState({ state: 'success', message: initiateRes });
      } catch (error: any) {
        let errorMessage = 'Internal Server Error';
        if (error.code === 'ERR_NETWORK') {
          errorMessage = error.message;
        } else if (error.code === 'ERR_BAD_REQUEST') {
          errorMessage = error.response?.data?.error || 'Bad Request Error';
        }
        console.log(errorMessage);
        setState({ state: 'error', errorMessage })
      }
    }
  };

  const onUpdated = (refund_id: number, status: string) => {
    setRefunds(prevMenus =>
      prevMenus.map(refunds =>
        refunds.s_no === refund_id ? { ...refunds, status } : refunds
      )
    );
  }



  return state?.state !== 'loading' ? (
    <section className="h-full w-full max-w-[1280px] box-border p-3 flex flex-col gap-3">
      {
        initRefund &&
        <RefundAlert s_no={initRefund.s_no} original_transaction_id={initRefund.original_transaction_id} onCancelClick={() => setInitRefund(null)} onConfirmedClick={onRefundInitiate} />
      }
      {
        refunds.length === 0 && <InitialMessage message='No refunds found!' />
      }
      <h1 className="text-4xl font-semibold">Refunds</h1>
      <div className="box-border w-full">
        <ul className="flex flex-col gap-3 h-full box-border py-2">
          {
            refunds.map(refund => (
              <li className={`flex items-center flex-wrap justify-between box-border p-4 rounded gap-1 bg-gray-200`} key={refund.s_no}>
                <div className="flex items-center flex-wrap gap-2 mr-6 ">
                  <p className="text-xl text-center font-semibold text-red-500 mr-2">₹{refund.amount}</p>
                  <p className="text-xs text-center font-medium bg-gray-300 px-3 py-1 rounded-full">Order Id: {refund.order_id}</p>
                  <p className={`text-xs text-center font-medium bg-gray-300 px-3 py-1 rounded-full ${setStatusStyle(refund.status)}`}>{refund.status}</p>
                </div>

                {/* <p className="min-w-[230px] text-sm flex-1  font-medium text-slate-700 flex items-center gap-3 truncate">Order Id: {item.order_id}</p>
                <p className="min-w-[230px] text-sm flex-1 text-slate-700 flex items-center gap-3 truncate"><FaRegClock />{item.payment_date}</p> */}
                <p className="min-w-[230px] text-sm flex-1 text-slate-700 flex items-center gap-3 truncate"><FaRegClock />{refund.create_date}</p>
                {
                  refund.status !== 'PAYMENT_SUCCESS' &&
                  <button className="text-xs font-medium text-white bg-red-500 px-3 py-1 rounded-full" onClick={() => setInitRefund({ s_no: refund.s_no, original_transaction_id: refund.original_transaction_id })}>Proceed now</button>
                }
                <button className="text-xs font-medium text-blue-800 bg-gray-300 px-3 py-1 rounded-full" onClick={() => navigate(`/payments/${refund.payment_id}`)}>Payment details</button>
                <button className="text-xs font-medium text-blue-800 bg-gray-300 px-3 py-1 rounded-full" onClick={() => navigate(`/orders/${refund.order_id}`)}>Order details</button>
              </li>
            ))
          }
        </ul>
      </div>
      {
        state && state.state == 'error' &&
        <Alertdialog state='error' button_name='Close' message={state.errorMessage} onButtonClick={onAlertClose} />
      }
      {
        state && state.state == 'success' &&
        <Alertdialog state='success' button_name='Close' message={state.message} onButtonClick={onAlertClose} />
      }
    </section>
  ) : <InitialMessage message='Loading...' />
}

const InitialMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <label className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">{message}</label>
  )
}

const RefundAlert: React.FC<{ s_no: number, original_transaction_id: string, onCancelClick: () => void, onConfirmedClick: (original_transaction_id: string) => void }> = ({ s_no, original_transaction_id, onCancelClick, onConfirmedClick }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onConfirmed = (tId: string) => {
    setLoading(true);
    onConfirmedClick(tId);
  }
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#00000094]">
      <div className="max-w-[400px] bg-white rounded flex flex-col">
        <p className="p-4">Are are you sure proceeding the refund?</p>
        <div className="flex border-t">
          <button className="flex-1 p-2 text-red-500 font-medium" disabled={loading} onClick={onCancelClick}>Cancel</button>
          <button className="flex-1 p-2 font-medium text-blue-700 flex items-center justify-center" disabled={loading} onClick={() => onConfirmed(original_transaction_id)}>{loading ? <ReactLoading type={'balls'} color={'blue'} height={25} width={25} /> : 'Confirm'}</button>
        </div>
      </div>
    </div>
  )
}

function setStatusStyle(status: string) {
  switch (status) {
    case 'PAYMENT_SUCCESS':
      return 'text-green-700';
    case 'PAYMENT_PENDING':
      return 'text-yellow-700';
    default:
      return 'text-red-700';
  }
}


export default Refunds