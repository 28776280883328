// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function getMenus(token: string) {
  try {
    const response = await axios.get(`${apiUrl}api/menus`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

async function addMenu(token: string, data: object) {
  try {
    const response = await axios.post(`${apiUrl}api/menus`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function updateMenu(token: string, menu_id: number, data: object) {
  try {
    const response = await axios.put(`${apiUrl}api/menus/${menu_id}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function removeMenu(token: string, menu_id: number) {
  try {
    const response = await axios.delete(`${apiUrl}api/menus/${menu_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function uploadImage(token: string, data: object) {
  try {
    const response = await axios.post(`${apiUrl}api/menus/upload`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

export { getMenus, addMenu, updateMenu, removeMenu, uploadImage };
