import React, { useEffect, useRef, useState } from 'react'
import Alertdialog from '../../components/alertdialog/Alertdialog'
import { getStates } from './Util';
const isAdminLoggedIn = () => {
    return localStorage.getItem('admin');
}

type Sales = {
    total_online_sale_today_price: string;
    total_cash_sale_today_price: string;
    total_online_sale_this_week_price: string;
    total_cash_sale_this_week_price: string;
    total_online_sale_this_month_price: string;
    total_cash_sale_this_month_price: string;
    total_online_sale_this_year_price: string;
    total_cash_sale_this_year_price: string;

}

type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', message: string }
type FailedState = { state: 'error', errorMessage: string }
type GetState = LoadingState | SuccessState | FailedState

function Dashboard() {
    const [state, setState] = useState<GetState | null>(null);
    const [sales, setSales] = useState<Sales>();
    const admin = useRef<any>(isAdminLoggedIn());
    const isMountedRef = useRef<boolean>(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            fetchData();
            isMountedRef.current = true;
        }
    }, [])

    // useEffect(() => {
    //     if (state?.state === 'accessed') {
    //         setSales(state.states);
    //     }
    // }, [state]);

    const fetchData = async () => {
        if (admin.current) {
            try {
                const statesRes = await getStates(admin.current);
                setSales(statesRes);
            } catch (error: any) {
                let errorMessage = 'Internal Server Error';
                if (error.code === 'ERR_NETWORK') {
                    errorMessage = error.message;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage = error.response?.data?.error || 'Bad Request Error';
                }
                console.log(errorMessage);
                setState({ state: 'error', errorMessage });
            }
        }
    };

    const onAlertClose = (state: string) => {
        // if (state === 'error') {
        //     localStorage.removeItem('admin');
        //     window.location.replace('/');
        // } else {
        //     setState(null);
        // }
        setState(null);
    }
    return (
        <section className="h-full w-full max-w-[1280px] box-border p-3 flex flex-col gap-3">
            <h1 className="text-2xl font-medium">Dashboard</h1>
            <div className="h-full w-full flex flex-col items-center">
                <div className="flex-1"></div>
                <div className="box-border w-full max-w-[700px] grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4">
                    <div className="flex flex-col gap-3 items-center bg-gray-200 box-border p-6 rounded">
                        <p className="text-lg font-semibold text-slate-800">Total Sale Today</p>
                        <div className="w-[80%] flex items-center">
                            <p className="flex-1 flex flex-col items-center border-r text-slate-800 border-slate-600"> <span className="text-4xl font-bold">₹{sales?.total_online_sale_today_price}</span><span className="text-xs">Online</span></p>
                            <p className="flex-1 flex flex-col items-center text-slate-800"> <span className="text-4xl font-bold">₹{sales?.total_cash_sale_today_price}</span><span className="text-xs">Cash</span></p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 items-center bg-gray-200 box-border p-6 rounded">
                        <p className="text-lg font-semibold text-slate-800">Total Sale this Week</p>
                        <div className="w-[80%] flex items-center">
                            <p className="flex-1 flex flex-col items-center border-r text-slate-800 border-slate-600"> <span className="text-4xl font-bold">₹{sales?.total_online_sale_this_week_price}</span><span className="text-xs">Online</span></p>
                            <p className="flex-1 flex flex-col items-center text-slate-800"> <span className="text-4xl font-bold">₹{sales?.total_cash_sale_this_week_price}</span><span className="text-xs">Cash</span></p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 items-center bg-gray-200 box-border p-6 rounded">
                        <p className="text-lg font-semibold text-slate-800">Total Sale this Month</p>
                        <div className="w-[80%] flex items-center">
                            <p className="flex-1 flex flex-col items-center border-r text-slate-800 border-slate-600"> <span className="text-4xl font-bold">₹{sales?.total_online_sale_this_month_price}</span><span className="text-xs">Online</span></p>
                            <p className="flex-1 flex flex-col items-center text-slate-800"> <span className="text-4xl font-bold">₹{sales?.total_cash_sale_this_month_price}</span><span className="text-xs">Cash</span></p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 items-center bg-gray-200 box-border p-6 rounded">
                        <p className="text-lg font-semibold text-slate-800">Total Sale this Year</p>
                        <div className="w-[80%] flex items-center">
                            <p className="flex-1 flex flex-col items-center border-r text-slate-800 border-slate-600"> <span className="text-4xl font-bold">₹{sales?.total_online_sale_this_year_price}</span><span className="text-xs">Online</span></p>
                            <p className="flex-1 flex flex-col items-center text-slate-800"> <span className="text-4xl font-bold">₹{sales?.total_cash_sale_this_year_price}</span><span className="text-xs">Cash</span></p>
                        </div>
                    </div>
                </div>
                <div className="flex-1"></div>
            </div>
            {
                state && state.state == 'error' &&
                <Alertdialog state={'error'} button_name={'Close'} message={state.errorMessage} onButtonClick={onAlertClose} />
            }
        </section>
    )
}

export default Dashboard