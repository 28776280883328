import { faCube, faGauge, faCreditCard, faListUl, faUsers, faChartSimple, faRightFromBracket, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const sidelists = [
    { url: '/', name: 'Dashboard', icon: <FontAwesomeIcon icon={faGauge} /> },
    { url: '/orders', name: 'Orders', icon: <FontAwesomeIcon icon={faCube} /> },
    { url: '/payments', name: 'Payments', icon: <FontAwesomeIcon icon={faCreditCard} /> },
    { url: '/refunds', name: 'Refunds', icon: <FontAwesomeIcon icon={faRotateLeft} />},
    { url: '/items', name: 'Items', icon: <FontAwesomeIcon icon={faListUl} /> },
    { url: '/users', name: 'Users', icon: <FontAwesomeIcon icon={faUsers} /> },
    { url: '/reports', name: 'Reports', icon: <FontAwesomeIcon icon={faChartSimple} /> },
    { url: '/reports', name: 'Logout', icon: <FontAwesomeIcon icon={faRightFromBracket} /> },
]

function Sidebar() {
    const { pathname } = useLocation();
    const logout = () => {
        localStorage.removeItem('admin');
        window.location.reload();
    }
    return (
        <footer className="fixed h-[70px] left-0 bottom-0 right-0 bg-white shadow-2xl shadow-black flex items-center justify-center overflow-auto pb-1">
            <ul className="w-full max-w-[1280px] flex gap-2 lg:gap-6">
                <div className="flex-1 min-w-1"></div>
                {sidelists.map(item => (
                    <li className="flex items-center justify-center box-border" key={item.name}>
                        {
                            item.name !== 'Logout' ?
                                <Link to={item.url} className={`py-2 text-md flex flex-col items-center gap-1 rounded transition-all duration-200 ease-in-out ${pathname === item.url ? 'bg-gray-200 text-blue-800 w-[90px]' : 'text-slate-700 w-[70px]'}`}>
                                    {item.icon}<span className="text-xs">{item.name}</span>
                                </Link>
                                :
                                <button className={`ml-6 px-3 py-2 text-md flex  items-center gap-3 rounded-full bg-slate-800 text-red-500`} onClick={logout}>
                                    {item.icon}<span className="text-sm font-medium">{item.name}</span>
                                </button>
                        }
                    </li>
                ))}
                <div className="flex-1 min-w-1"></div>
            </ul>
        </footer>
    )
}

export default Sidebar