import { faBan, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
    state: 'error' | 'success';
    button_name: string;
    message: string;
    onButtonClick: (state: 'error' | 'success') => void;
}

const Alertdialog: React.FC<Props> = ({ state, button_name, message, onButtonClick }) => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#00000094]">
            <div className="max-w-[400px] w-[90%] flex flex-col bg-white rounded shadow">
                <p className="flex flex-col items-center gap-2 p-4"><FontAwesomeIcon icon={state === 'error' ? faBan : faCircleCheck} style={{ fontSize: '30px' }} />{message}</p>
                <button className="border-t p-2 font-medium" onClick={() => onButtonClick(state)}>{button_name}</button>
            </div>
        </div>
    );
};

export default Alertdialog;
