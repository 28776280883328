// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;


async function login(data: object) {
  try {
    const response = await axios.post(`${apiUrl}api//admin/login`, data);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}


export { login };
