// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function getUsers(token: string, page: number, limit: number) {
  try {
    const response = await axios.get(`${apiUrl}api//users?page=${page}&limit=${limit}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function getUserById(token: string, user_id: string) {
  try {
    const response = await axios.get(`${apiUrl}api//users/${user_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}



export { getUsers, getUserById };
