// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function getRefunds(token: string, page: number, limit: number) {
  try {
    const response = await axios.get(`${apiUrl}api//refunds?page=${page}&limit=${limit}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function updateRefunds(token: string, data: object) {
  try {
    const response = await axios.put(`${apiUrl}api//refunds`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function initiateRefund(token: string, data: object) {
  try {
    const response = await axios.post(`${apiUrl}api/pay/initiate-refund`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

export { getRefunds, updateRefunds, initiateRefund };
