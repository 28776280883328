import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import ReactLoading from 'react-loading';
import Alertdialog from '../../components/alertdialog/Alertdialog';
import { addMenu, getMenus, removeMenu, updateMenu, uploadImage } from './Util';
const isAdminLoggedIn = () => {
  return localStorage.getItem('admin');
}
type Menu = {
  id: number;
  name: string;
  price: number;
  img: string;
  qnty: number;
  isAvailable: boolean;
}

type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', message: string }
type FailedState = { state: 'error', errorMessage: string }
type EditState = { state: 'edit', menu_id?: number, img?: string, name?: string, price?: number }
type MenuState = LoadingState | SuccessState | FailedState | EditState

function Items() {
  const [state, setState] = useState<MenuState | null>(null);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [imgPreview, setImgPreview] = useState<string | null>(null);
  const admin = useRef<any>(isAdminLoggedIn());
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      fetchMenus();
      isMountedRef.current = true;
    }
  }, []);

  const fetchMenus = async () => {
    if (admin.current) {
      setState({ state: 'loading' });
      try {
        const menuRes = await getMenus(admin.current);
        if (menuRes) {
          setMenus(menuRes);
          setState({ state: 'success', message: 'Accesssed' });
        }
      } catch (error: any) {
        let errorMessage = 'Internal Server Error';
        if (error.code === 'ERR_NETWORK') {
          errorMessage = error.message;
        } else if (error.code === 'ERR_BAD_REQUEST') {
          errorMessage = error.response?.data?.error || 'Bad Request Error';
        }
        console.log(errorMessage);
        setState({ state: 'error', errorMessage });
      }
    }
  };

  const onMenuUpdated = useCallback((menu_id: number, updatedData: object) => {
    Object.entries(updatedData).forEach(([key, value]) => {
      setMenus(prevMenus =>
        prevMenus.map(menu =>
          menu.id === menu_id ? { ...menu, [key]: value } : menu
        )
      );
    });
    setState(null);
  }, [])

  const onAlertClose = (state: string) => {
    setState(null);
  }

  return state?.state !== 'loading' ? (
    <section className="h-full w-full max-w-[1280px] box-border p-3 flex flex-col gap-3">
      {
        imgPreview && <div className="fixed top-0 left-0 right-0 bottom-16 box-border bg-[#00000094] p-10 flex flex-col items-center gap-4">
          <button className='text-white font-bold self-end flex items-center gap-2' onClick={() => setImgPreview(null)}><FontAwesomeIcon icon={faXmark} />Close</button>
          <div className="h-full w-full flex flex-col md:items-center overflow-auto">
            <div className="flex-1"></div>
            <img className="max-w-[500px] rounded" src={imgPreview} alt="" />
            <div className="flex-1 min-h-10"></div>
          </div>
        </div>
      }
      {menus.length === 0 &&
        <InitialMessage message='No menus found!' />}
      <div className="flex items-center gap-3 justify-between">
        <h1 className="text-4xl font-semibold">Items</h1>
        <button className="flex items-center gap-2 font-medium" onClick={() => setState({ state: 'edit' })}><FontAwesomeIcon icon={faPlus} />Add new</button>
      </div>
      <div className="box-border w-full">
        <ul className="w-full flex flex-col gap-3 box-border py-2">
          {
            menus.map((menu, index) => (
              <li className="p-3 flex  items-center gap-4 transition-all duration-300 ease-in-out rounded bg-gray-200" key={index}>
                <img className="w-[80px] self-start max-h-[80px] object-cover rounded" src={menu.img} alt={menu.name} onClick={() => setImgPreview(menu.img)} />
                <div className="flex-1 h-full flex flex-wrap">
                  <div className="flex-1 flex flex-wrap">
                    <label className="flex-1 min-w-[130px] text-sm font-medium">{menu.name}</label>
                    <label className="flex-1 min-w-[130px] text-sm font-medium">₹{menu.price}</label>
                  </div>
                  <div className="flex-1 flex flex-col gap-1">
                    <label className="text-sm font-medium">Is available?</label>
                    <AvailableButtons admin={admin.current} menu_id={menu.id} isAvailable={menu.isAvailable} onUpdated={onMenuUpdated} onUpdateFailed={(e) => setState({ state: 'error', errorMessage: e })} />
                  </div>
                </div>
                <button className="h-10 min-w-10 rounded-full bg-gray-300 border text-sm" onClick={() => setState({ state: 'edit', menu_id: menu.id, img: menu.img, name: menu.name, price: menu.price })}><FontAwesomeIcon icon={faPenToSquare} /></button>
              </li>
            ))
          }
        </ul>
      </div>
      {
        state?.state === 'edit' &&
        <EditElement admin={admin} menu_id={state.menu_id} img={state.img} name={state.name} price={state?.price?.toString()} onClose={() => setState(null)} onUpdated={onMenuUpdated} onUpdateFailed={(e) => setState({ state: 'error', errorMessage: e })} />
      }

      {
        state && state.state === 'error' &&
        <Alertdialog state='error' button_name='Close' message={state.errorMessage} onButtonClick={onAlertClose} />
      }

    </section>
  ) : <InitialMessage message='Loading...' />
}

const AvailableButtons: React.FC<{ admin: string, menu_id: number, isAvailable: boolean, onUpdated: (menu_id: number, updatedData: object) => void, onUpdateFailed: (message: string) => void }> = ({ admin, menu_id, isAvailable, onUpdated, onUpdateFailed }) => {
  const handleAvailableChange = async (val: 1 | 0) => {
    try {
      const updateRes = await updateMenu(admin, menu_id, { isAvailable: val });
      if (updateRes) onUpdated(menu_id, { isAvailable: val });
    } catch (error: any) {
      let errorMessage = 'Internal Server Error';
      if (error.code === 'ERR_NETWORK') {
        errorMessage = error.message;
      } else if (error.code === 'ERR_BAD_REQUEST') {
        errorMessage = error.response?.data?.error || 'Bad Request Error';
      }
      console.log(errorMessage);
      onUpdateFailed(errorMessage);
    }
  }
  return (
    <div className="w-fit flex items-center gap-1 justify-center p-1 bg-gray-300 rounded-full">
      <button className={`text-xs font-medium rounded-full h-6 w-10 ${isAvailable ? 'bg-green-600 text-white' : ''}`} onClick={() => handleAvailableChange(1)}>Yes</button>
      <button className={`text-xs font-medium rounded-full h-6 w-10 ${!isAvailable ? 'bg-red-600 text-white' : ''}`} onClick={() => handleAvailableChange(0)}>No</button>
    </div>
  )
}


const EditElement: React.FC<{ admin: any, menu_id?: number, img?: string, name?: string, price?: string, onClose: () => void, onUpdated: (menu_id: number, updatedData: object) => void, onUpdateFailed: (message: string) => void }> = ({ admin, menu_id, img, name, price, onClose, onUpdated, onUpdateFailed }) => {
  const [formData, setFormData] = useState<{ img: string, name: string, price: string }>({ img: '', name: name || '', price: price || '' });
  const [loading, setLoading] = useState<{ state: 'update' | 'delete', isLoading: boolean } | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(img || '');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target;

    if (name === 'price' && !/^\d*$/g.test(value)) {
      return;
    }

    if (type === 'file' && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setFormData((prevData) => ({
          ...prevData,
          [name]: file
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    setLoading({ state: 'update', isLoading: true });

    const handleError = (error: any) => {
      const errorMessage = error.code === 'ERR_NETWORK' ? error.message
        : error.code === 'ERR_BAD_REQUEST' ? error.response?.data?.error || 'Bad Request Error'
          : 'Internal Server Error';
      console.log(errorMessage);
      onUpdateFailed(errorMessage);
    };

    const handleUpdate = async (formData: any) => {
      if (menu_id) {
        const updateRes = await updateMenu(admin, menu_id, formData);
        if (updateRes) {
          onUpdated(menu_id, { name: formData.name, price: formData.price });
        }
      }
    };

    const handleAdd = async (formData: any) => {
      const addRes = await addMenu(admin, formData);
      if (addRes) {
        window.location.reload();
      }
    };

    try {
      const { img, ...restData } = formData;
      if (img) {
        const uploadRes = await uploadImage(admin, { image: img, menu_id: menu_id });
        if (uploadRes) {
          formData.img = uploadRes;
        } else {
          throw new Error('Image upload failed');
        }
      }

      if (menu_id) {
        await handleUpdate(img ? formData : restData);
      } else {
        await handleAdd(formData);
      }

    } catch (error: any) {
      handleError(error);
    } finally {
      setLoading({ state: 'update', isLoading: false });
    }
  };



  async function deleteMenu() {
    if (menu_id) {
      setLoading({ state: 'delete', isLoading: true });
      try {
        const updateRes = await removeMenu(admin, menu_id);
        if (updateRes) window.location.reload();
      } catch (error: any) {
        let errorMessage = 'Internal Server Error';
        if (error.code === 'ERR_NETWORK') {
          errorMessage = error.message;
        } else if (error.code === 'ERR_BAD_REQUEST') {
          errorMessage = error.response?.data?.error || 'Bad Request Error';
        }
        console.log(errorMessage);
        onUpdateFailed(errorMessage);
      } finally {
        setLoading({ state: 'delete', isLoading: true });
      }
    }
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-16 flex flex-col items-center bg-[#00000094] overflow-auto box-border py-5">
      <div className="flex-1"></div>
      <div className="max-w-[450px] w-[90%] bg-white flex flex-col gap-3 box-border p-4 rounded">
        <button className="h-10 w-10 self-end border rounded" onClick={onClose}><FontAwesomeIcon icon={faXmark} /></button>
        {
          selectedImage && <img className="max-h-[250px] object-contain" src={selectedImage as string}></img>
        }
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-1">
            <label className="text-sm" htmlFor="img">Image</label>
            <input className="border-2 border-black rounded px-3 py-2 font-medium" type="file" id='img' name='img' onChange={handleInputChange} required={menu_id === undefined} />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm" htmlFor="name">Menu name</label>
            <input className="border-2 border-black rounded px-3 py-2 font-medium" type="text" id='name' name='name' placeholder='enter menu name' value={formData.name} onChange={handleInputChange} required />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm" htmlFor="price">Price</label>
            <input className="border-2 border-black rounded px-3 py-2 font-medium" type="text" id='price' name='price' placeholder='enter price' value={formData.price} onChange={handleInputChange} required />
          </div>
          <button className="bg-black rounded text-white font-medium flex items-center justify-center h-10" type='submit'>{loading?.isLoading && loading?.state === 'update' ? <ReactLoading type={'balls'} color={'white'} height={30} width={30} /> : 'Save'}</button>
        </form>
        {
          menu_id && <button className="bg-red-600 rounded text-white font-medium flex items-center justify-center h-10" onClick={deleteMenu}>{loading?.isLoading && loading?.state === 'delete' ? <ReactLoading type={'balls'} color={'white'} height={30} width={30} /> : 'Delete'}</button>
        }
      </div>
      <div className="flex-1"></div>
    </div>
  )
}

const InitialMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <label className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">{message}</label>
  )
}

export default Items