import React, { useState, ChangeEvent, useRef } from 'react';
import { getReports } from './Util';
import generatePDF from 'react-to-pdf';

const isAdminLoggedIn = () => {
  return localStorage.getItem('admin');
}

type Params = { label: string; placeholder: string; input: string, limit: number };
type Day = { state: 'day'; params: [Params] };
type DayRange = { state: 'dayRange'; params: [Params] | [Params, Params] };
type Month = { state: 'month'; params: [Params] };
type MonthRange = { state: 'monthRange'; params: [Params] | [Params, Params] };
type Year = { state: 'year'; params: [Params] };
type YearRange = { state: 'yearRange'; params: [Params, Params] };
type DateState = Day | DayRange | Month | MonthRange | Year | YearRange;

type Report = { name: string, total_quantity: number, total_sales: number }

function Reports() {
  const [state, setState] = useState<DateState | null>(null);
  const [reports, setReports] = useState<Report[]>([]);
  const admin = useRef<any>(isAdminLoggedIn());
  const [date, setDate] = useState<string>('');
  const targetRef = useRef<HTMLDivElement | null>(null);
  const options = {
    orientation: 'portrait',
    filename: 'brunch_receipt.pdf',
    unit: 'in',
    format: [4, 2]
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    switch (value) {
      case 'day':
        setState({ state: 'day', params: [{ label: 'Day', placeholder: 'yyyy/mm/dd', input: '', limit: 10 }] });
        break;
      case 'dayRange':
        setState({
          state: 'dayRange',
          params: [{ label: 'Start Day', placeholder: 'yyyy/mm/dd', input: '', limit: 10 }, { label: 'End Day', placeholder: 'yyyy/mm/dd', input: '', limit: 10 }],
        });
        break;
      case 'month':
        setState({ state: 'month', params: [{ label: 'Month', placeholder: 'yyyy/mm', input: '', limit: 7 }] });
        break;
      case 'monthRange':
        setState({
          state: 'monthRange',
          params: [{ label: 'Start Month', placeholder: 'yyyy/mm', input: '', limit: 7 }, { label: 'End Month', placeholder: 'yyyy/mm', input: '', limit: 7 }],
        });
        break;
      case 'year':
        setState({ state: 'year', params: [{ label: 'Year', placeholder: 'yyyy', input: '', limit: 4 }] });
        break;
      case 'yearRange':
        setState({
          state: 'yearRange',
          params: [{ label: 'Start Year', placeholder: 'yyyy', input: '', limit: 4 }, { label: 'End Year', placeholder: 'yyyy', input: '', limit: 4 }],
        });
        break;
      default:
        setState(null);
        break;
    }
  };


  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (state) {
      const newState = { ...state };
      newState.params[index].input = value;
      setState(newState);
    }

    const formDataValues = state?.params.map(param => param.input) || [];
    const mergeDate = formDataValues.join('-');
    setDate(mergeDate);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (admin.current && state) {
      const formDataValues = state?.params.map(param => param.input) || [];
      const mergedValues = formDataValues.join('&end=');
      const mergeParams = `${state.state}?start=${mergedValues}`
      try {
        const reportRes = await getReports(admin.current, mergeParams);
        if (reportRes) {
          setReports(reportRes);
          console.log(reportRes);
        }
      } catch (error: any) {
        let errorMessage = 'Internal Server Error';
        if (error.code === 'ERR_NETWORK') {
          errorMessage = error.message;
        } else if (error.code === 'ERR_BAD_REQUEST') {
          errorMessage = error.response?.data?.error || 'Bad Request Error';
        }
        console.log(errorMessage);
        setReports([]);
      }
    }
  };

  return (
    <section className="h-full w-full max-w-[1280px] box-border p-3 flex flex-col gap-3">
      <h1 className="text-2xl font-medium">Reports</h1>
      <div className="h-full w-full flex flex-col gap-6 items-start bg-stone-100 box-border p-6">
        <div className=" flex flex-col gap-5">
          <div className="flex items-center gap-4 justify-between">
            <label htmlFor="date">Select options</label>
            <select className="px-3 py-2 rounded" name="date" id="date" onChange={handleSelectChange}>
              <option value="">--Select Options--</option>
              <option value="day">By Day</option>
              <option value="dayRange">By Day Range</option>
              <option value="month">By Month</option>
              <option value="monthRange">By Month Range</option>
              <option value="year">By Year</option>
              <option value="yearRange">By Year Range</option>
            </select>
          </div>

          <form className="w-full flex flex-wrap items-center gap-4" onSubmit={handleSubmit}>
            {state &&
              state.params.map((param, index) => (
                <div key={index} className="flex items-center gap-4">
                  <label htmlFor={`input-${index}`}>{param.label}</label>
                  <input
                    type="text"
                    id={`input-${index}`}
                    value={param.input}
                    placeholder={param.placeholder}
                    onChange={(e) => handleChangeInput(e, index)}
                    className="px-3 py-2 rounded text-center max-w-[150px]"
                    required
                    maxLength={param.limit}
                    minLength={param.limit}
                  />
                  {/* {errors[index] && <span className="text-red-500">{errors[index]}</span>} */}
                </div>
              ))}
            {
              state && state?.params.length > 0 && <button type='submit' className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Generate</button>
            }
          </form>
        </div>
        {
          reports.length > 0 && <button className="bg-blue-500 text-white text-sm font-medium rounded-full px-3 py-1 self-end" onClick={() => generatePDF(targetRef, options)}>Download</button>
        }

        <div className="w-full flex md:justify-center overflow-auto">
          <div className="min-w-[790px] min-h-[1100px] bg-white box-border p-10 flex flex-col gap-6" ref={targetRef}>
            <div className="flex flex-col gap-4">
              <p className="text-2xl font-medium">Reports</p>
              <p className="w-full text-center text-4xl font-semibold">The Royal Veg</p>
              <p className="w-full text-center text-md">{date}</p>
              {/* <p className="w-full text-center text-md">12/12/2024 - 23/10/2025</p> */}
            </div>

            <table className="box-border">
              <thead>
                <tr>
                  <th className="p-3 text-start border">Item name</th>
                  <th className="p-3 text-start border">Quantity</th>
                  <th className="p-3 text-start border">Sub-Amount</th>
                </tr>
              </thead>
              <tbody>
                {
                  reports.map(report => (
                    <tr key={report.name}>
                      <td className="p-3 text-start border" >{report.name}</td>
                      <td className="p-3 text-start border">{report.total_quantity}</td>
                      <td className="p-3 text-start border">₹{report.total_sales * report.total_quantity}</td>
                    </tr>
                  ))
                }
                {
                  reports.length > 0 &&
                  <tr >
                    <th className="text-end border p-3" colSpan={2}>Total Amount</th>
                    <th className="p-3 text-start border">₹{reports.reduce((c, i) => c + (i.total_sales * i.total_quantity), 0)}</th>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Reports;
