import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronDown, faClock } from '@fortawesome/free-solid-svg-icons';
import { FaRegClock, FaCheckCircle, FaPhoneAlt } from "react-icons/fa";
import { FaCircleXmark, FaLocationArrow, FaRegMoneyBill1 } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Alertdialog from '../../components/alertdialog/Alertdialog';
import { getUsers } from './Util';


const isAdminLoggedIn = () => {
  return localStorage.getItem('admin');
}

type User = {
  user_id: number;
  fullname: string;
  phone: string;
  create_time: string;
  order_nos: string;
}
type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', message: string }
type FailedState = { state: 'error', errorMessage: string }
type UserState = LoadingState | SuccessState | FailedState

function Users() {
  const admin = useRef<any>(isAdminLoggedIn());
  const [state, setState] = useState<UserState | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10000);
  const [totalPages, setTotalPages] = useState<number>(0);
  const isMountedRef = useRef<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMountedRef.current) {
      fetchUsers();
      isMountedRef.current = true;
    }
  }, []);

  const fetchUsers = async () => {
    if (admin.current)
      setState({ state: 'loading' });
    try {
      const usersRes = await getUsers(admin.current, page, limit);
      if (usersRes && usersRes.users && usersRes.totalPages) {
        setUsers(usersRes.users);
        setTotalPages(usersRes.totalPages);
      };
      setState({ state: 'success', message: 'Accessed' });
    } catch (error: any) {
      let errorMessage = 'Internal Server Error';
      if (error.code === 'ERR_NETWORK') {
        errorMessage = error.message;
      } else if (error.code === 'ERR_BAD_REQUEST') {
        errorMessage = error.response?.data?.error || 'Bad Request Error';
      }
      console.log(errorMessage);
      setState({ state: 'error', errorMessage });
    }
  };


  const onAlertClose = (state: string) => {
    // if (state === 'error') {
    //   localStorage.removeItem('admin');
    //   window.location.replace('/');
    // } else {
    //   setState(null);
    // }
    setState(null);
  }

  return state?.state !== 'loading' ? (
    <section className="h-full w-full max-w-[1280px] box-border p-3 flex flex-col gap-3">
      {
        users.length === 0 && <InitialMessage message='No users found!' />
      }
      <h1 className="text-4xl font-semibold">Users</h1>
      <div className="box-border w-full">
        <ul className="flex flex-col gap-3 h-full box-border py-2">
          {
            users.map(user => (
              <li className={`flex items-center flex-wrap justify-between box-border p-4 rounded gap-2 bg-gray-200`} key={user.user_id}>
                <div className="flex-1 flex flex-wrap items-center gap-2">
                <p className="min-w-[230px] text-sm flex-1  font-medium text-slate-700 flex items-center gap-3 truncate"><FaRegUser />{user.fullname}</p>
                {
                  user.phone &&
                  <p className="min-w-[230px] text-sm flex-1  font-medium text-slate-700 flex items-center gap-3 truncate"><FaPhoneAlt />{user.phone}</p>

                }
                </div>
                <p className="min-w-[250px] text-sm flex-1 text-slate-700 flex items-center gap-3 truncate"><FaRegClock />Create at: {user.create_time}</p>
                <p className="min-w-[130px] text-xs text-center bg-gray-300 px-3 py-1 rounded-full">Total orders: {user.order_nos}</p>
                <button className="text-xs font-medium text-white bg-blue-500 px-3 py-1 rounded-full" onClick={() => navigate(`/orders/user?userId=${user.user_id}&username=${user.fullname}`)}>Orders</button>
              </li>
            ))
          }
        </ul>
      </div>
      {
        state && state.state == 'error' &&
        <Alertdialog state={'error'} button_name={'Close'} message={state.errorMessage} onButtonClick={onAlertClose} />
      }
    </section>
  ) : <InitialMessage message='Loading...' />
}

const InitialMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <label className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">{message}</label>
  )
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default Users