// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function getOrders(token: string, page: number, limit: number) {
  try {
    const response = await axios.get(`${apiUrl}api//orders?page=${page}&limit=${limit}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function getOrdersUserById(token: string, user_id: string) {
  try {
    const response = await axios.get(`${apiUrl}api//orders/${user_id}/user`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}


async function updateOrder(token: string, order_id: string, data: object) {
  try {
    const response = await axios.put(`${apiUrl}api//orders/${order_id}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}



export { getOrders, getOrdersUserById,updateOrder };
