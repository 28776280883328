import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronDown, faClock } from '@fortawesome/free-solid-svg-icons';
import { FaRegClock, FaCheckCircle, FaPhoneAlt } from "react-icons/fa";
import { FaCircleXmark, FaLocationArrow, FaRegMoneyBill1 } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Alertdialog from '../../components/alertdialog/Alertdialog';
import { getPayments } from './Util';


const isAdminLoggedIn = () => {
  return localStorage.getItem('admin');
}

type Payment = {
  s_no: number;
  order_id: number;
  client: string;
  amount: string;
  payment_mode: string;
  transaction_id: string;
  payment_date: string;
  status: string;
}

type LoadingState = { state: 'loading' }
type SuccessState = { state: 'success', message: string }
type FailedState = { state: 'error', errorMessage: string }
type PaymentState = LoadingState | SuccessState | FailedState

function Payments() {
  const admin = useRef<any>(isAdminLoggedIn());
  const [state, setState] = useState<PaymentState | null>(null);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10000);
  const [totalPages, setTotalPages] = useState<number>(0);
  const isMountedRef = useRef<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMountedRef.current) {
      fetchPayments();
      isMountedRef.current = true;
    }
  }, [])

  const fetchPayments = async () => {
    if (admin.current)
      setState({ state: 'loading' });
    try {
      const payRes = await getPayments(admin.current, page, limit);
      if (payRes && payRes.payments && payRes.totalPages) {
        setPayments(payRes.payments);
        setTotalPages(payRes.totalPages);
      };
      setState({ state: 'success', message: 'Accessed' });
    } catch (error: any) {
      let errorMessage = 'Internal Server Error';
      if (error.code === 'ERR_NETWORK') {
        errorMessage = error.message;
      } else if (error.code === 'ERR_BAD_REQUEST') {
        errorMessage = error.response?.data?.error || 'Bad Request Error';
      }
      console.log(errorMessage);
      setState({ state: 'error', errorMessage })
    }
  };


  const onAlertClose = (state: string) => {
    // if (state === 'error') {
    //   localStorage.removeItem('admin');
    //   window.location.replace('/');
    // } else {
    //   setState(null);
    // }

    setState(null);
  }

  return state?.state !== 'loading' ? (
    <section className="h-full w-full max-w-[1280px] box-border p-3 flex flex-col gap-3">
      {
        payments.length === 0 && <InitialMessage message='No payments found!' />
      }
      <h1 className="text-4xl font-semibold">Payments</h1>
      <div className="box-border w-full">
        <ul className="flex flex-col gap-3 h-full box-border py-2">
          {
            payments.map(item => (
              <li className={`flex items-center flex-wrap justify-between box-border p-4 rounded gap-1 bg-gray-200`} key={item.s_no}>
                <div className="flex items-center flex-wrap gap-2 mr-6 ">
                  <p className="text-xl text-center font-semibold text-green-800 mr-2">₹{item.amount}</p>
                  <p className="text-xs text-center font-medium bg-gray-300 px-3 py-1 rounded-full">{capitalizeFirstLetter(item.payment_mode)}</p>
                  <p className={`text-xs text-center font-medium bg-gray-300 px-3 py-1 rounded-full ${setStatusStyle(item.status)}`}>{item.status}</p>
                </div>

                <p className="min-w-[230px] text-sm flex-1  font-medium text-slate-700 flex items-center gap-3 truncate">Order Id: {item.order_id}</p>
                <p className="min-w-[230px] text-sm flex-1 text-slate-700 flex items-center gap-3 truncate"><FaRegClock />{item.payment_date}</p>
                <button className="text-xs font-medium text-white bg-blue-500 px-3 py-1 rounded-full" onClick={()=>navigate(`/payments/${item.s_no}`)}>More</button>
                <button className="text-xs font-medium text-blue-800 bg-gray-300 px-3 py-1 rounded-full" onClick={() => navigate(`/orders/${item.order_id}`)}>Order details</button>
              </li>
            ))
          }
        </ul>
      </div>
      {
        state && state.state == 'error' &&
        <Alertdialog state='error' button_name='Close' message={state.errorMessage} onButtonClick={onAlertClose} />
      }
    </section>
  ) : <InitialMessage message='Loading...' />
}

const InitialMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <label className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">{message}</label>
  )
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function setStatusStyle(status: string) {
  switch (status) {
    case 'PAYMENT_SUCCESS':
      return 'text-green-700';
    case 'PAYMENT_PENDING':
      return 'text-yellow-700';
    default:
      return 'text-red-700';
  }
}


export default Payments